import * as ConnectIcons from '@portal/icons/connect';
import * as WandIcons from '@portal/icons/wand';
import { getCurrentBrandName } from './utils';

export const getBrandSpecificIcons = () => {
  let iconsModule;
  const currentBrandName = getCurrentBrandName();

  switch (currentBrandName) {
    case 'Connect':
      iconsModule = ConnectIcons;
      break;
    default:
      iconsModule = WandIcons;
  }

  return {
    AnalyzingIcon: iconsModule.AnalyzingIcon,
    ChatBot: iconsModule.ChatBot,
    ChatAiAvatar: iconsModule.ChatAiAvatar,
    AssistantLogoW: iconsModule.AssistantLogoW,
    LogoW: iconsModule.LogoW,
    MagicCircle: iconsModule.MagicCircle,
  };
};
