import { ConfigResolverReturnType, ParsedJSONValueType, PossibleBrandNamesType, TraverseTreeFuncType } from './types';
import { isObject } from '~/lib/type-guards';

export const getCurrentBrandName = () => (process.env.NEXT_PUBLIC_BRAND_NAME as PossibleBrandNamesType) || 'Wand';

export const traverseConfigTree: TraverseTreeFuncType = <P>(
  config: ParsedJSONValueType,
  pathParts: string[],
): ConfigResolverReturnType<P> => {
  if (pathParts.length === 1) {
    const result = config[pathParts[0]];

    if (isObject(result)) {
      return result as ConfigResolverReturnType<P>;
    }

    return { props: {} } as ConfigResolverReturnType<P>;
  }

  const nextConfigScope = config[pathParts[0]];

  if (!isObject(nextConfigScope)) return { props: {} } as ConfigResolverReturnType<P>;

  return traverseConfigTree(nextConfigScope, pathParts.splice(1));
};
